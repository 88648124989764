var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, Row, Line, extend } from 'elemental-react';
var MenuIcon = function (_a) {
    var _b = _a.color, color = _b === void 0 ? 'black' : _b, props = __rest(_a, ["color"]);
    return (_jsx(Box, __assign({ width: 48, height: 48, px: "4px", py: "6px", justifyContent: "space-between" }, props, { children: (new Array(3).fill(null)).map(function (_, i) { return (_jsx(Line, { bg: color, height: "4px", width: "40px" }, i)); }) }), void 0));
};
var Title = extend(Text, function () { return ({
    color: 'black',
    ml: 16,
    fontFamily: 'IBM Plex Serif',
    bold: true,
    fontSize: 28,
    lineHeight: 32,
}); });
// @ts-ignore
Title.defaultProps = {
    children: 'ZPublish',
};
var Flex = function () { return (_jsx(Box, { flex: 1 }, void 0)); };
var AppBar = function (_a) {
    var _b = _a.title, title = _b === void 0 ? 'Title' : _b, color = _a.color, children = _a.children, props = __rest(_a, ["title", "color", "children"]);
    return (_jsx(Row, __assign({ p: 16, alignItems: "center", justifyContent: "space-between" }, props, { children: children || (_jsxs(_Fragment, { children: [_jsx(MenuIcon, { color: color }, void 0),
                _jsx(Title, __assign({ color: color }, { children: title }), void 0),
                _jsx(Flex, {}, void 0)] }, void 0)) }), void 0));
};
AppBar.MenuIcon = MenuIcon;
AppBar.Title = Title;
AppBar.Fill = Flex;
export default AppBar;
