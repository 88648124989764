var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Headline, } from 'elemental-react';
var SectionTitle = function (props) { return (_jsx(Headline.H5, __assign({ name: "SectionTitle", mb: 3, bold: true }, props), void 0)); };
var Section = function (_a) {
    var _b = _a.p, p = _b === void 0 ? [16, 40, 0] : _b, _c = _a.pt, pt = _c === void 0 ? 40 : _c, _d = _a.pb, pb = _d === void 0 ? 40 : _d, children = _a.children, props = __rest(_a, ["p", "pt", "pb", "children"]);
    return (_jsx(Box, __assign({ as: "section", p: p, pt: pt, pb: pb }, props, { children: _jsx(Box, __assign({ width: "100%", maxWidth: 1024, alignSelf: "center" }, { children: children }), void 0) }), void 0));
};
Section.Title = SectionTitle;
export default Section;
